import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "content-spinner"
}
const _hoisted_2 = {
  key: 0,
  class: "content-spinner"
}
const _hoisted_3 = {
  key: 1,
  class: "rcmnd_stamp"
}
const _hoisted_4 = { class: "rs_content" }
const _hoisted_5 = { key: 0 }
const _hoisted_6 = {
  key: 0,
  class: "h_btns_img"
}
const _hoisted_7 = {
  key: 1,
  class: "h_btns_img"
}
const _hoisted_8 = { class: "rs_link_title" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_back_button = _resolveComponent("ion-back-button")
  const _component_ion_buttons = _resolveComponent("ion-buttons")
  const _component_ion_title = _resolveComponent("ion-title")
  const _component_ion_toolbar = _resolveComponent("ion-toolbar")
  const _component_ion_header = _resolveComponent("ion-header")
  const _component_ion_refresher_content = _resolveComponent("ion-refresher-content")
  const _component_ion_refresher = _resolveComponent("ion-refresher")
  const _component_ion_spinner = _resolveComponent("ion-spinner")
  const _component_ion_img = _resolveComponent("ion-img")
  const _component_Empty = _resolveComponent("Empty")
  const _component_ion_infinite_scroll_content = _resolveComponent("ion-infinite-scroll-content")
  const _component_ion_infinite_scroll = _resolveComponent("ion-infinite-scroll")
  const _component_ion_content = _resolveComponent("ion-content")
  const _component_ion_page = _resolveComponent("ion-page")

  return (_openBlock(), _createBlock(_component_ion_page, null, {
    default: _withCtx(() => [
      _createVNode(_component_ion_header, null, {
        default: _withCtx(() => [
          _createVNode(_component_ion_toolbar, { class: "sub_header" }, {
            default: _withCtx(() => [
              _createVNode(_component_ion_buttons, { slot: "start" }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_back_button, {
                    "default-href": "/",
                    text: ""
                  })
                ]),
                _: 1
              }),
              _createVNode(_component_ion_title, null, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.state.group.data.name), 1)
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_ion_content, { fullscreen: false }, {
        default: _withCtx(() => [
          _createVNode(_component_ion_refresher, {
            slot: "fixed",
            onIonRefresh: _cache[1] || (_cache[1] = ($event: any) => (_ctx.onRefresh($event)))
          }, {
            default: _withCtx(() => [
              _createVNode(_component_ion_refresher_content)
            ]),
            _: 1
          }),
          (_ctx.state.contentLoading)
            ? (_openBlock(), _createBlock("div", _hoisted_1, [
                _createVNode(_component_ion_spinner, { name: "crescent" })
              ]))
            : (_openBlock(), _createBlock(_Fragment, { key: 1 }, [
                (_ctx.state.stamp.loading && _ctx.state.stamp.items.length == 0)
                  ? (_openBlock(), _createBlock("div", _hoisted_2, [
                      _createVNode(_component_ion_spinner, { name: "crescent" })
                    ]))
                  : (0 < _ctx.state.stamp.items.length)
                    ? (_openBlock(), _createBlock("div", _hoisted_3, [
                        _createVNode("div", _hoisted_4, [
                          (!_ctx.state.contentLoading)
                            ? (_openBlock(), _createBlock("ul", _hoisted_5, [
                                (_openBlock(true), _createBlock(_Fragment, null, _renderList(_ctx.state.stamp.items, (item, index) => {
                                  return (_openBlock(), _createBlock("li", {
                                    class: "rs_list",
                                    key: `items_${index}`
                                  }, [
                                    _createVNode("a", {
                                      href: "#",
                                      onClick: _withModifiers(($event: any) => (_ctx.onStampLink(item.id, item.name)), ["prevent","stop"]),
                                      class: "rs_link_box"
                                    }, [
                                      (item.picture.name)
                                        ? (_openBlock(), _createBlock("div", _hoisted_6, [
                                            _createVNode(_component_ion_img, {
                                              src: `${item.picture.url}${item.picture.path}?size=150`,
                                              alt: item.content.title,
                                              style: 
                            item.acquisition == 0
                              ? _ctx.state.stampFillters[_ctx.state.randomNum]
                              : ''
                          
                                            }, null, 8, ["src", "alt", "style"])
                                          ]))
                                        : (_openBlock(), _createBlock("div", _hoisted_7)),
                                      _createVNode("p", _hoisted_8, _toDisplayString(item.content.title), 1)
                                    ], 8, ["onClick"])
                                  ]))
                                }), 128))
                              ]))
                            : _createCommentVNode("", true)
                        ])
                      ]))
                    : (_openBlock(), _createBlock(_component_Empty, {
                        key: 2,
                        message: `'${_ctx.state.group.data.name}' 스탬프가 없습니다.`
                      }, null, 8, ["message"])),
                (_ctx.state.stamp.items.length != 0)
                  ? (_openBlock(), _createBlock(_component_ion_infinite_scroll, {
                      key: 3,
                      threshold: "100px",
                      id: "infinite-scroll",
                      onIonInfinite: _cache[2] || (_cache[2] = ($event: any) => (_ctx.onLoadData($event))),
                      disabled: _ctx.state.isScrollDisabled
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_ion_infinite_scroll_content, { "loading-spinner": "crescent" })
                      ]),
                      _: 1
                    }, 8, ["disabled"]))
                  : _createCommentVNode("", true)
              ], 64))
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}